<template>
  <div class="popup_layer txt" :style="{'width' : width}">
    <div class="popup_contents">
      <p class="txt_set" v-html-custom="text"></p>
    </div>
    <div class="popup_footer">
      <div class="bt_popup">
        <button
          type="button"
          class="bt_rblack"
          @click.stop="onClose()"
        >
          확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    width:{
      type: String
    },
    closeFunc:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose() {
        if(this.closeFunc){
            this.$attrs.close();
            this.$emit('close');
        }else{
            this.$emit('close');
        }
    }
  }
};
</script>
