import Login from "./login";

export default {
  extends: Login,

  data: () => ({
    naver: undefined
  }),

  beforeMount() {
    try {
      let options = {
        clientId: this.$env.naverClientId,
        callbackUrl: `${this.$env.siteUrl}/login/naver`,
        isPopup: true
      };

      let naverLogin = new this.$naverLogin.LoginWithNaverId(options);
      naverLogin.init();
      this.naver = naverLogin;
    } catch (e) {
    }
  },

  mounted() {
    this.$loadScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js")
      .then(() => {
        try {
          let config = {
            clientId: this.$env.APPLE_CLIENT_ID || "",
            scope: "name email",
            redirectURI: this.getUrl(`${this.$env.siteUrl}/login/apple`),
            usePopup: true
          };

          window.AppleID.auth.init(config);

          document.addEventListener("AppleIDSignInOnSuccess", this.onAppleIDSignInOnSuccess);
          document.addEventListener("AppleIDSignInOnFailure", this.onAppleIDSignInOnFailure);

          try {
            let el = document.getElementById("appleid-signin");
            // el.innerHTML = this.$route.fullPath.startsWith("/join?referrer=join") ? '<label>애플 아이디로 가입하기</label>' : "애플 아이디로 가입하기";
            el.innerHTML = this.$route.fullPath.startsWith("/join") ? '<label>애플 아이디로 간편 가입하기</label>' : "애플 로그인";
          } catch (e) {
            // Element not found
          }
        } catch (e) {
          // console.error(e);
        }
      });
  },

  beforeDestroy() {
    document.removeEventListener("AppleIDSignInOnSuccess", this.onAppleIDSignInOnSuccess);
    document.removeEventListener("AppleIDSignInOnFailure", this.onAppleIDSignInOnFailure);
  },

  methods: {
    /**
     * 네이버 로그인
     */
    doNaverLogin() {
      if(this.snsLoginProgress) return;
      this.snsLoginProgress = true;
      try {
        if (this.naver !== undefined) {
          try {
            if(this.setAutoLoginCookie){
              this.setAutoLoginCookie();
            }
          } catch (e) {}
          
          if (this.$store.state.layout.isApp) {
            
            if(this.$device.isAndroid){
              // Android 전용 분기 뒤로가기 안되는문제
              let t = this.naver.generateAuthorizeUrl();
              var element = document.createElement('a');
              element.setAttribute('target', '_blank');
              element.setAttribute('href', t);
              element.setAttribute('rel', 'opener');
              element.click();
              window.doNaverLoginResult = (accessToken) => {
                const params = {
                  copAccDc: '02',
                  copTkn: accessToken,
                }
                return this.sociLogin(params)
              };
            } else {
              this.naver.authorize();
              window.doNaverLoginResult = undefined;
            }
          } else {
            let t = this.naver.generateAuthorizeUrl();
            window.open(t, "naver_login", "height=800,width=600", false);
            window.doNaverLoginResult = (accessToken) => {
            const params = {
                copAccDc: '02',
                copTkn: accessToken,
              }
              return this.sociLogin(params)
            };
          }
        }

      } finally {
        this.snsLoginProgress = false;
      }
    },

    /**
     * 네이버 로그인
     */
    /* doNaverLogin() {
      if (this.naver !== undefined) {
        try {
          if(this.setAutoLoginCookie){
            this.setAutoLoginCookie();
          }
        } catch (e) {}

        if (this.$store.state.layout.isApp) {
          
          if(this.$device.isAndroid){
            // Android 전용 분기 뒤로가기 안되는문제
            let t = this.naver.generateAuthorizeUrl();
            var element = document.createElement('a');
            element.setAttribute('target', '_blank');
            element.setAttribute('href', t);
            element.setAttribute('rel', 'opener');
            element.click();
            window.doNaverLoginResult = (accessToken) => {
              return this.doOAuthLoginChain("NAVER", accessToken);
            };
          } else {
            this.naver.authorize();
            window.doNaverLoginResult = undefined;
          }
        } else {
          let t = this.naver.generateAuthorizeUrl();
          window.open(t, "naver_login", "height=800,width=600", false);
          window.doNaverLoginResult = (accessToken) => {
            return this.doOAuthLoginChain("NAVER", accessToken);
          };
        }
      }
    }, */

    // doNaverLoginTest() {
    //   if (this.naver !== undefined) {
    //     if (this.$store.state.layout.isApp) {
    //       if(this.$device.isAndroid){
    //         // Android 전용 분기 뒤로가기 안되는문제
    //         let t = this.naver.generateAuthorizeUrl();
    //         var element = document.createElement('a');
    //         element.setAttribute('target', '_blank');
    //         element.setAttribute('href', t);
    //         element.setAttribute('rel', 'opener');
    //         element.click();
    //         window.doNaverLoginResult = (accessToken) => {
    //           return this.doOAuthLoginChain("NAVER", accessToken);
    //         };
    //       } else {
    //         this.naver.authorize();
    //         window.doNaverLoginResult = undefined;
    //       }
    //     } else {
    //       let t = this.naver.generateAuthorizeUrl();
    //       window.open(t, "naver_login", "height=800,width=600", false);
    //       window.doNaverLoginResult = (accessToken) => {
    //         return this.doOAuthLoginChain("NAVER", accessToken);
    //       };
    //     }
    //   }
    // },

    /**
     * GFR 카카오 로그인
     */
    doKakaoLogin() {
      
      try {
        if(this.setAutoLoginCookie){
          this.setAutoLoginCookie();
        }
      } catch (e) {}

      Kakao.Auth.login({
        success: (object) => {
          return this.doOAuthLoginChain("KAKAO", object.refresh_token);
        },
        fail: (err) => {},
      });
    },

    /**
     * SSO 카카오 로그인 
     */
    doSsoKakaoLogin() {
      if(this.snsLoginProgress) return;
      this.snsLoginProgress = true;
      
      try {
        // auto login
        try {
          if(this.setAutoLoginCookie){
            this.setAutoLoginCookie();
          }
        } catch (e) {}

        // kakao login
        Kakao.Auth.login({
          success: (authObj) => {
            const copAccDc = '07';                     // 제휴계정구분코드: ‘07’(카카오)
            const copTkn = authObj.access_token;        // 제휴토큰
            const copRefreshTkn = authObj.refresh_token;

            // console.log("================== SNS KAKAO start ===============================");
            // console.log("authObj : " + authObj);
            // console.log("authObj.access_token : " + authObj.access_token);
            // console.log("authObj.token_type : " + authObj.token_type);
            // console.log("authObj.refresh_token : " + authObj.refresh_token);
            // console.log("authObj.expires_in : " + authObj.expires_in);
            // console.log("authObj.refresh_expires_in : " + authObj.expires_in);
            // console.log("================== SNS KAKAO end ===============================");

            // 통합회원제 소셜로그인 API 호출
            const params = {
              copAccDc,
              copTkn,
              copRefreshTkn,
            }
            this.sociLogin(params);
          },
          fail: (err) => {
            // 에러처리
          },
        });

      } finally {
        this.snsLoginProgress = false;
      }
    },

    /**
     * SSO 소셜 로그인 후처리 
     */
    sociLogin(params = {}){
      // console.log('==================== ㅇㅇ/ sociLogin 함수 실행 ====================');
      // console.log('ㅇㅇ/ copTkn : ' + copTkn);  
      // console.log('ㅇㅇ/ copAccDc : ' + copAccDc);

      //debugger;

      /** Client 라이브러리 초기화 */
      // var sso = new SsoClientLibrary({
      //     ccoSiteNo: CCO_SITE_NO,                // 제휴사사이트번호
      //     clntAkInf: '<%=clntAkInf%>',           // 클라이언트요청정보
      //     urEvnmtDc : '0',                       // 사용자환경구분코드
      //     vrblNm : 'sso',                        // 라이브러리변수
      //     srnOpt : {
      //       opMd : '0',                // 오픈모드
      //       rnHeaderExpsYn: 'Y'        // 화면헤더노출여부  
      //     } 
      // });

      const sso = this.$ssoLib.initSsoLogin({
        srnOpt : {
          opMd : '0',                // 오픈모드
          rnHeaderExpsYn: 'Y'        // 화면헤더노출여부  
        } 
      })

      const self = this
  
      /** API 호출 */
      sso.callLogin({ 
        akUrl: '/exBiz/login/sociLogin_01_001',    // 요청URL
        akDta: { 
          copAccDc: params.copAccDc,
          copTkn: params.copTkn,
          copAdInf: ''
        },   								   // 요청데이터
        aftPcMd : '0',                         // 후처리모드
        lgoSepYn: 'Y',                         // 로그아웃분리여부
        rturUrl: `${self.$env.siteUrl}/join/sso`,	// 리턴URL	회원가입일 경우 로그인하기 버튼에 대한 리턴url
        rturUrlCaloMethd: 'GET',				// 리턴URL호출메소드
        ccoDlyMsg : 'O0046',
        callback : async function(rspDta) {          // 콜백함수
          if('00' == rspDta.rspC && '401' == rspDta.rspDtc){
            

            // 로그인 상태 변경 (성공)

            // 그냥 소셜 로그인일 경우에는 리턴처리를 여기서 처리해야함.
            // console.log('ㅇㅇ/ 소셜로그인 성공');
            // console.log('rspDta.ssoTkn : ' + rspDta.ssoTkn); 
            // console.log('rspDta.acesTkn : ' + rspDta.acesTkn);
            // console.log('rspDta.rnwTkn : ' + rspDta.rnwTkn);

            let objData = {};
            objData["lMembersSsoTkn"]  = rspDta.ssoTkn;
            objData["lMembersAcesTkn"] = rspDta.acesTkn;
            objData["lMembersRnwTkn"]  = rspDta.rnwTkn;

            self.$store.commit("session/setLMembersTkn", objData);
            
            // TODO CONSOLE.LOG 제거
            // AutoLogin일 경우 토큰 쿠키에 저장
            const autoLoginStatus = self.$cookies.get('AUTO_LOGIN')
            console.log('>>Autologin status', autoLoginStatus)

            if (autoLoginStatus == 'Y') {
              self.$cookies.set(process.env.SSO_RNW_TKN_KEY, rspDta.rnwTkn, {
                path: '/',
                maxAge: 360 * 24 * 60 * 60,
              })
              console.log('Save autologin token', self.$cookies.get(process.env.SSO_RNW_TKN_KEY))
            }

            let form = [
              `lMembersAcesTkn=${self.$store.state.session.lMembersAcesTkn}`
            ];

            await self.$axios
            .$post("/api/login", form.join("&"), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-App-No": self.$store.state.layout.appNo,
                "X-App-YN": self.$store.state.layout.isApp ? "Y" : "N",
                "Cache-Control": "no-cache, no-store, must-revalidate"
              }
            })
            .then(async (response) => {
              let data = (response || {});
              if(data.code == 'SUCCESS_LOGIN'){
                try {
                  if (params.copAccDc == '07') {
                    const syncParams = {
                      siteId : self.$env.siteNo,
                      snsAcessToken : params.copTkn,
                      snsRefreshToken : params.copRefreshTkn,
                      acesTkn : rspDta.acesTkn,
                      oAuthType : params.copAccDc
                    }
                    await self.$axios.$post('/api/lmembers/sso/ssoSociLoginSync', syncParams)
                  } else if (params.copAccDc == '02') {
                    const syncParams = {
                      siteId : self.$env.siteNo,
                      snsAcessToken : params.copTkn,
                      snsRefreshToken : params.copRefreshTkn,
                      acesTkn : rspDta.acesTkn,
                      oAuthType : params.copAccDc
                    }
                    await self.$axios.$post('/api/lmembers/sso/ssoSociLoginSync', syncParams)
                  }
                } catch (error) {
                  
                }
                await self.afterLoginHandler(false, true);
                let profile = await self.$axios.$get("/api/user/me");                  
                self.$store.commit("session/set", profile);
                // setCookie("AUTO_LOGIN", "N", 0);
              }
            });
            //비밀번호 변경 캠페인 화면 호출
            sso.callScreen({
                              akUrl: '/view/login/login_04_001',   // 요청URL
                              rturUrl: 'https://www.kway.co.kr/',          // 제휴사리턴URL
                              rturUrlCaloMethd: 'GET'    //제휴사 리턴URL호출메소드
                  });

          }else if ('00' == rspDta.rspC) {

            // 로그인 상태 변경 (성공)

            // 그냥 소셜 로그인일 경우에는 리턴처리를 여기서 처리해야함.
            // console.log('ㅇㅇ/ 소셜로그인 성공');
            // console.log('rspDta.ssoTkn : ' + rspDta.ssoTkn); 
            // console.log('rspDta.acesTkn : ' + rspDta.acesTkn);
            // console.log('rspDta.rnwTkn : ' + rspDta.rnwTkn);

            let objData = {};
            objData["lMembersSsoTkn"]  = rspDta.ssoTkn;
            objData["lMembersAcesTkn"] = rspDta.acesTkn;
            objData["lMembersRnwTkn"]  = rspDta.rnwTkn;

            self.$store.commit("session/setLMembersTkn", objData);

            // TODO CONSOLE.LOG 제거
            // AutoLogin일 경우 토큰 쿠키에 저장
            const autoLoginStatus = self.$cookies.get('AUTO_LOGIN')
            console.log('>>Autologin status', autoLoginStatus)

            if (autoLoginStatus == 'Y') {
              self.$cookies.set(process.env.SSO_RNW_TKN_KEY, rspDta.rnwTkn, {
                path: '/',
                maxAge: 360 * 24 * 60 * 60,
              })
              console.log('Save autologin token', self.$cookies.get(process.env.SSO_RNW_TKN_KEY))
            }

            let form = [
              `lMembersAcesTkn=${self.$store.state.session.lMembersAcesTkn}`
            ];

            await self.$axios
            .$post("/api/login", form.join("&"), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-App-No": self.$store.state.layout.appNo,
                "X-App-YN": self.$store.state.layout.isApp ? "Y" : "N",
                "Cache-Control": "no-cache, no-store, must-revalidate"
              }
            })
            .then(async (response) => {
              let data = (response || {});
              if(data.code == 'SUCCESS_LOGIN'){
                try {
                  if (params.copAccDc == '07') {
                    const syncParams = {
                      siteId : self.$env.siteNo,
                      snsAcessToken : params.copTkn,
                      snsRefreshToken : params.copRefreshTkn,
                      acesTkn : rspDta.acesTkn,
                      oAuthType : params.copAccDc
                    }
                    await self.$axios.$post('/api/lmembers/sso/ssoSociLoginSync', syncParams)
                  } else if (params.copAccDc == '02') {
                    const syncParams = {
                      siteId : self.$env.siteNo,
                      snsAcessToken : params.copTkn,
                      snsRefreshToken : params.copRefreshTkn,
                      acesTkn : rspDta.acesTkn,
                      oAuthType : params.copAccDc
                    }
                    await self.$axios.$post('/api/lmembers/sso/ssoSociLoginSync', syncParams)
                  }
                } catch (error) {
                  
                }
                await self.afterLoginHandler(false, true);
                let profile = await self.$axios.$get("/api/user/me");                  
                self.$store.commit("session/set", profile);
                // setCookie("AUTO_LOGIN", "N", 0);
              }
            })
            .catch(async (err) => {

            });


          }else{  
            // 로그인 실패 처리
            // console.log('dd/ 소셜로그인 실패');
          }
        }
      });
    },

    onAppleIDSignInOnSuccess({ detail }) {
      try {
        if(this.setAutoLoginCookie){
          this.setAutoLoginCookie();
        }
      } catch (e) {}
      
      let form = document.createElement("form");
      form.setAttribute("action", this.getUrl(`${this.$env.siteUrl}/login/apple`));
      form.setAttribute("method", "POST");

      form.innerHTML = `
        <input type="hidden" name="code" value="${detail.authorization.code}" />
        <input type="hidden" name="id_token" value="${detail.authorization.id_token}" />
      `;

      document.body.appendChild(form);
      form.submit();
    },

    onAppleIDSignInOnFailure(e) {
      // console.error(e);
      if (e.error != "popup_closed_by_user") {
        this.$modal.error("애플 로그인에 실패하였습니다.");
      }
    }
  }
};
