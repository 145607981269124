<template>
  <section class="sub_para02" :class="{'sub_para':!$device.isMobile}">
    <div class="title_box mgb30">
      <h4 class="title_1st tc" :class="{'dp_none':$device.isMobile}">로그인</h4>
      <!-- <p class="bt_text_s_9 tc" @click="incrementHiddenNumber()">롯데지에프알 통합회원 로그인<br />기존 롯데지에프알 통합회원의 경우 별도의 회원가입 없이 사이트 이용이 가능합니다.</p> -->
    </div>
    <!-- 간편 로그인 -->
    <div class="mar_set" :class="{'w400 mgt60':!$device.isMobile}">
      <div class="title_box" :class="{'title_box_s':!$device.isMobile}">
        <h4 class="title_2nd tc">SNS 계정으로 로그인하기</h4>
      </div>
      <ul class="login_sns">
        <li>
          <button type="button" class="bt_naver" @click.stop="doNaverLogin()">
            네이버 로그인
          </button>
        </li>
        <li>
          <button type="button" class="bt_kakao" @click.stop="doSsoKakaoLogin()">
            카카오 로그인
          </button>
        </li>
        <li>
          <button id="appleid-signin" type="button" class="bt_apple">
            애플 로그인
          </button>
        </li>
      </ul>
    </div>

    
    <form @submit.prevent="onSubmit" novalidate>
      <!-- K-WAY 로그인 -->
      <div class="title_box mgt70" :class="{'title_box_s':!$device.isMobile}">
        <h4 class="title_2nd tc">이메일(일반) 계정으로 로그인하기</h4>
      </div>
      <div class="mar_set mgt30" :class="{ 'w400': !$device.isMobile }">
        
        <div class="input_common mgb30">
          <input
            ref="username"
            placeholder="아이디"
            type="email"
            v-model="form.username"
            required
          />
          <div class="bt_close" @click.stop="form.username = undefined">
            <img src="/web/images/icon_common_close.png" alt="닫기" />
          </div>
        </div>
        <div class="input_common">
          <input
            ref="password"
            placeholder="비밀번호"
            type="password"
            v-model="form.password"
            required
          />
          <div class="bt_close" @click.stop="form.password = undefined">
            <img src="/web/images/icon_common_close.png" alt="닫기" />
          </div>
        </div>
        <dl class="login_dl">
          <dt>
            <label class="checkbox_wrap" v-if="$device.isMobile">
              <input type="checkbox" v-model="form.autoLogin" />
              <i class="check_icon"></i>
              <span style="font-size:12px;">로그인 유지</span>
            </label>
            <label class="checkbox_wrap">
              <input type="checkbox" v-model="form.remember" />
              <i class="check_icon"></i>
              <span style="font-size:12px;">아이디 저장</span>
            </label>
          </dt>
          <!--dt>
            <label class="checkbox_wrap">
              <input type="checkbox" v-model="form.remember" />
              <i class="check_icon"></i>
              <span style="font-size:10px;">아이디 저장</span>
            </label>
          </dt-->
          <dd>
            <nuxt-link
              :to="{ path: '/member/find-id', query: $route.query }"
              class="bt_text_s_9 f12"
            >
              아이디찾기
            </nuxt-link>
            <i class="vline"></i>
            <nuxt-link
              :to="{ path: '/member/reset-password', query: $route.query }"
              class="bt_text_s_9 f12"
            >
              비밀번호 재설정
            </nuxt-link>
            <i class="vline"></i>
            <a
              href="#;"
              class="bt_text_s_9 f12"
              @click.prevent="onFindLMembersPwd"
            >
              L.POINT 비밀번호 재설정
            </a>
          </dd>
        </dl>
        <dl>
          <dt>
            <span class="txt_s" v-if="form.autoLogin" style="font-size: 10px !important;"><span style="font-size: 11px !important;">로그인 유지</span> 옵션은 개인 정보 보호를 위해 본인 기기에서만 이용해 주세요.</span>
          </dt>
        </dl>
        <div class="bt_2nd">
          <button type="submit" class="bt_rblack">로그인</button>
        </div>
      </div>
    </form>
    
    <div class="title_box">
      <!-- 회원가입 비회원주문  -->
      <ul class="mar_set mgt30 flex gap30 col" :class="{'w400': !$device.isMobile }">
        <!--<div class="txt_center_desc">
          아직 회원이 아니신가요?<br />NICE CLAUP(롯데 GFR)의 회원이 되셔서<br />다양한 혜택을
          만나보세요.
        </div>-->
        <li>
          <div class="bt_rgray_grayborder lpoint_join_area">
            <div class="txt_area">
              <div class="summary">
                아직 회원이 아니신가요?
              </div>
              <div class="details">
                L.POINT 통합 회원 및 롯데GFR 회원으로 가입하시면 다양한 혜택을 받으실 수 있습니다.
              </div>
            </div>
            <div class="bt_5th">
              <button
                type="button"
                class="bt_rblue"
                style="background:#20202C;"
                @click.stop="$router.push({ path: '/join'
                , query: {...$route.query
                  , referrer: ['order','gift'].indexOf($route.query.referrer) >= 0 ? 
                  $route.query.referrer+'_join' : 
                  'join' } })"
              >
                회원가입
              </button>
            </div>
          </div>
        </li>
        <li>
          <div
            class="bt_5th"
            v-if="($route.query.referrer || '').toLowerCase() === 'order'"
          >
            <button
              type="button"
              class="bt_rwhite_blackborder"
              @click.stop="$router.push({ path: '/order', query: {} })"
            >
              비회원 주문
            </button>
          </div>
          <div class="bt_5th" v-else>
            <button
              type="button"
              class="bt_rwhite_blackborder"
              @click.stop="onClickGuestOrder()"
            >
              비회원 주문조회
            </button>
          </div>
        </li>
      </ul>
    </div>
    <!-- 회원가입 비회원주문  -->
    <!-- <div class="mar_set mgt50" :class="{'w400 mgt100': !$device.isMobile }">
      <div class="tc">
        <button
          type="button"
          class="bt_rwhite_s join_btn"
          @click.stop="$router.push({ path: '/join'
           , query: {...$route.query
                        , referrer: ['order','gift'].indexOf($route.query.referrer) >= 0 ? 
                            $route.query.referrer+'_join' : 
                            'join' } })"
        >
          회원가입하기
        </button>
      </div>
      <div
        class="tc mgt10"
        v-if="($route.query.referrer || '').toLowerCase() === 'order'"
      >
        <button
          type="button"
          class="bt_underline_9"
          @click.stop="$router.push({ path: '/order', query: {} })"
        >
          비회원 주문
        </button>
      </div>
      <div class="tc mgt10" v-else>
        <button
          type="button"
          class="no_member bt_underline_9"
          @click.stop="onClickGuestOrder()"
        >
          비회원 주문조회
        </button>
      </div>
    </div> -->
  </section>
</template>

<script>
import * as common from '@/assets/libs/common';
import { isEmailPattern, isEmptyString } from "@/assets/libs/validate";
import SimpleLogin from "@/assets/mixins/simple-login";
import Common from "@/assets/mixins/common";
import ChangeLpointModal from '@/components/commons/modal/login/ChangeLpointModal';
import LoginErrorModal from "@/pages/login/modal/LoginErrorModal"

const STORAGE_KEY = "LGFRUSERNAME";

export default {
  mixins: [SimpleLogin, Common],

  middleware: ["anonymous-only"],


  data: () => ({
    form: {
      username: undefined,
      password: undefined,
      remember: false,
      autoLogin: true,
    },
    hiddenNumber: 0,
  }),

  fetch({ store }) {
    store.commit("layout/setMobileHeader", {
      headerComponentName: "page",
      routeName: "로그인",
      hasFooter: false,
    });
  },

  /*
  head: () => ({
    title: "로그인",
  }),
  */

  head() {
    return {
      title: "로그인",
      meta: [
        {
          hid: "viewport",
          name: "viewport",
          content: `${this.viewport}`
        }
      ],
    }
  },

  computed: {
    viewport() {
      if (this.$device.isTablet) {
        //console.log("is Tablet");
        return "width=1440";
      } else {
        return "viewport-fit=auto,initial-scale=1,minimum-scale=1,maximum-scale=1,width=device-width,target-densitydpi=device-dpi";
      }
    },
  },

  mounted() {
    let username = window.localStorage.getItem(STORAGE_KEY);
    if (username) {
      this.form.username = username;
      this.form.remember = true;
      this.$refs.password.focus();
    } else {
      this.$refs.username.focus();
    }

    //테스트용으로 if 주석처리, 테스트 완료되면 if 주석 걷어내자
    if(!this.$device.isMobile) {
      this.form.autoLogin = false;
    }

    // try {
    //   let r = new URL(document.referrer);
    //   let c = new URL(location.href);
    //
    //   console.log(r.pathname, c.pathname);
    //
    //   if (c.pathname === r.pathname) {
    //     return this.$router.push("/");
    //   }
    // } catch (e) {
    // }
  },

  methods: {
    onFindLMembersPwd() {
      const sso = this.$ssoLib.initFindPassword()
      sso.callScreen({
        akUrl: '/exView/manage/fdPassword_01_001',
        popWidth : "700",
        popHeight : "650",
        rturUrl: `${this.$env.siteUrl}/login`,
        rturUrlCaloMethd: 'GET'                     //  리턴URL호출메소드
      });
    },
    
    /**
     * 일반 JDBC 로그인
     */
    async onSubmit() {
      await this.clearAfterLogout()

      const validFormFunc = () => {
        if (process.env.LMEMBERS_SSO_JOIN_YN == "Y") {
          return this.validateSsoForm()
        } else {
          return this.validateForm()
        }
      }

      return validFormFunc().then(async () => {
        try {
          this.form.password = encodeURI(this.form.password); 
          let form = [
            `username=${this.form.username}`,
            `password=${this.form.password}`,
            `lMembersAcesTkn=${common.cfGetValue(this.$store.state.session.lMembersAcesTkn)}`,
          ];

          try {
            if(this.setAutoLoginCookie){
              this.setAutoLoginCookie();
            }
          } catch (e) {}

          await this.$axios
            .$post("/api/login", form.join("&"), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-App-No": this.$store.state.layout.appNo,
                "X-App-YN": this.$store.state.layout.isApp ? "Y" : "N"
              }
            })
            .then(async () => {

              if (this.form.remember) {
                window.localStorage.setItem(STORAGE_KEY, this.form.username);
              } else {
                window.localStorage.removeItem(STORAGE_KEY);
              }
            });
            // 화면 확인하고싶을때 this.validateForm().then() 위에 this.changeLpointMember 영역 넣고 await 를 return 으로 변경
          // if(LPOINT 통합 회원 전환 대상일때) {
            // await this.changeLpointMember()
            //   .then(async () => {
            //     try {
            //       await this.$modal.success("L.POINT 통합 회원 전환");
            //     } catch (e) {
            //       await this.$modal.error("L.POINT 통합 회원 전환 실패");
            //     } finally {
            //       // this.$router.go(0);
            //     }
            //   })
            //   .catch(async () => {
            //     try {
            //       await this.$modal.success("3개월간 노출 안함");
            //     } catch (e) {
            //       await this.$modal.error("3개월간 노출 안함 실패");
            //     } finally {
            //       // this.$router.go(0);
            //     }
            //   });
          // }
          return this.afterLoginHandler();
        } catch (e) {
          let state = ((e || {}).response || {}).status;
          let data = ((e || {}).response || {}).data;
          let message = data.message;
          let code = (data || {}).code || '';

          if (state === 504) message = "서버에 접속할 수 없습니다.";
          else if (code === 'ERR_HIBERNATE_USER') {
            let cache = JSON.stringify(data);
            cache = encodeURI(cache);
            this.$cookies.set(process.env.DORMANT_INFO_KEY, btoa(cache), {
              path: '/',
              maxAge: 60 * 5
            });
            return this.$router.push({path: '/member/active'});
          } else if (state === 403) {
            if (code === "COUNT_OVER") {
              /*this.$modal.error(message)
                .then(() => {
                  this.$router.push({ path: '/member/reset-password' });
                  return;
                });*/
              let text = message;
              let width = this.$device.isMobile?'340px':'380px';
              this.$modal.show(
                LoginErrorModal,
                {
                  text,
                  width,
                  closeFunc: true,
                  close: () => {
                    this.$nextTick(() => this.movePage());
                  }
                },
                {
                  //여기 width, height 값적용 안됩니다
                  width: "360px",
                  height: "auto",
                }
              );
                
              return;
            } else {
              this.$modal.error(message);
              return;
            }
          }else if(state === 502){
            //아이디 비밀번호 재확인
            //this.$modal.error(data);
            let text = data;
            let width = this.$device.isMobile?'340px':'380px';
            this.$modal.show(
              LoginErrorModal,
              {
                text,
                width
              },
              {
                //여기 width, height 값적용 안됩니다
                width: "360px",
                height: "auto",
              }
            );
          }else if(code == 'ERR_MEM_ALREADY_DELETED'){
            let text = "아이디 또는 비밀번호를 다시 확인해 주세요.";
            let width = this.$device.isMobile?'340px':'380px';
            this.$modal.show(
              LoginErrorModal,
              {
                text,
                width
              },
              {
                //여기 width, height 값적용 안됩니다
                width: "360px",
                height: "auto",
              }
            );
          }
        }
      });
    },

    movePage(){
      this.$router.push({ path: '/member/reset-password' });
    },

    validateForm() {
      return new Promise((resolve, reject) => {
        if (isEmptyString(this.form.username)) {
          return;
          this.$modal.error("아이디를 반드시 입력해 주세요.").then(() => {
            this.$refs.username.focus();
            return reject();
          });
        }

        if (!isEmailPattern(this.form.username)) {
          return this.$modal.error("이메일 형식이 맞지 않습니다.<br/>확인 후 다시 입력해 주세요")
            .then(() => {
              this.form.username = undefined;
              this.$refs.username.focus();
              return reject();
            });
        }

        if (isEmptyString(this.form.password)) {
          return this.$modal
            .error("비밀번호를 반드시 입력해 주세요.")
            .then(() => {
              this.$refs.password.focus();
              return reject();
            });
        }

        return resolve();
      });
    },

    async validateSsoForm() {
    
      // SSO 초기화
      let ssoClientInitInfo = await this.$lMembersService.ssoClientInit();

      let urEvnmtDc = "0";
      if(this.$device.isMobile) {
        if (this.$store.state.layout.isApp) {
          urEvnmtDc = "2";
        }
        urEvnmtDc = "1";
      }
      
      let userId = this.form.username;
      let userIdDupYn = "N";
      if(!isEmptyString(this.form.username)) {
        userIdDupYn = await this.$lMembersService.ssoUserIdDupYn(userId);
      }

      return new Promise((resolve, reject) => {
        if (isEmptyString(this.form.username)) {
          this.loginProgress = false;
          return this.$modal.error("아이디를 반드시 입력해 주세요.").then(() => {
            this.$refs.username.focus();
            return reject();
          });
        }

        if (isEmptyString(this.form.password)) {
          this.loginProgress = false;
          return this.$modal
            .error("비밀번호를 반드시 입력해 주세요.")
            .then(() => {
              this.$refs.password.focus();
              return reject();
            });
        }

        if(userIdDupYn == "N") {
          // 롯데멤버스 회원이 아님 -> GFR 로그인
          return resolve();
        }

        // SSO Login
        let sso = null;
        let sso1 = null;
        let self = this;
        
        sso = this.$ssoLib.initSsoLogin()

        sso.callLogin({
          akUrl: '/exBiz/login/login_01_001',
          akDta: {
            onlId: self.form.username,
            cstPswd: self.form.password
          },
          aftPcMd: "1",  // 후처리모드
          rturUrl: window.location.href,
          rturUrlCaloMethd: 'GET',
          callback : function(rspDta) {
            let rspC   = rspDta.rspC;
            let rspDtc = rspDta.rspDtc;
	
            if(rspC == "00" && rspDtc == "401") {
              let objData = {};
              objData["lMembersSsoTkn"]  = rspDta.ssoTkn;
              objData["lMembersAcesTkn"] = rspDta.acesTkn;
              objData["lMembersRnwTkn"]  = rspDta.rnwTkn;

              window.sessionStorage.setItem('lMembersTknObj', JSON.stringify(objData))
              self.$store.commit("session/setLMembersTkn", objData);

              if (self.$device.isMobile && self.form.autoLogin) {
                self.setCookie(process.env.SSO_RNW_TKN_KEY, rspDta.rnwTkn, 360)
              }

              let form = [
              `lMembersAcesTkn=${self.$store.state.session.lMembersAcesTkn}`
            ];

            self.$axios
            .$post("/api/login", form.join("&"), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-App-No": self.$store.state.layout.appNo,
                "X-App-YN": self.$store.state.layout.isApp ? "Y" : "N",
                "Cache-Control": "no-cache, no-store, must-revalidate"
              }
            })
            .then(async (response) => {
              let data = (response || {});
              if(data.code == 'SUCCESS_LOGIN'){
                await self.afterLoginHandler(false, true);
                let profile = await self.$axios.$get("/api/user/me");                  
                self.$store.commit("session/set", profile);
                // setCookie("AUTO_LOGIN", "N", 0);
              }
            });
            
            // SSO 초기화
            sso1 = new SsoClientLibrary({
            ccoSiteNo: ssoClientInitInfo.ccoSiteNo,  // 제휴사사이트번호
            clntAkInf: ssoClientInitInfo.clntAkInf,  // 클라이언트요청정보
            acesTkn : rspDta.acesTkn,     // 접근토큰
            vrblNm: "sso",						 		// 라이브러리 변수명
            urEvnmtDc : urEvnmtDc,        // 사용자환경구분코드 0: PC Web 1: Mobile Web 2: Mobile App
            srnOpt : {opMd : '0'},         // 오픈모드 0: Redirect 1: iframe 2: Popup
            });
            
            //비밀번호 변경 캠페인 화면 호출
            sso1.callScreen({
                              akUrl: '/view/login/login_04_001',   // 요청URL
                              rturUrl: 'https://www.kway.co.kr/',          // 제휴사리턴URL
                              rturUrlCaloMethd: 'GET'    //제휴사 리턴URL호출메소드
                  });

            }else if('44' == rspC && '405' == rspDtc){
          //비밀번호 오류횟수가 5회 초과하였습니다.
          alert("비밀번호 오류횟수가 5회 초과하였습니다.");
                sso.callScreen({
                  akUrl: '/exView/manage/fdPassword_01_001',
                  popWidth : "700",
                  popHeight : "650",
                  rturUrl: `${self.$env.siteUrl}/login`,
                  rturUrlCaloMethd: 'GET'                     //  리턴URL호출메소드
                });
          }else if('44' == rspC && '412' == rspDtc){
          //강제 차단 상태 회원입니다.
            alert("강제 차단 상태 회원입니다.");
          return;
          }else if('44' == rspC && '403' == rspDtc){
          //접근 제한 대상 IP 입니다.
            alert("접근 제한 대상 IP 입니다.");
          return;
          }else if('44' == rspC && '414' == rspDtc){
          //정회원 전환 가능 대상입니다
          alert("정회원 전환 가능 대상입니다");
          sso.callScreen({
            akUrl: '/exView/join/mbrJoin_16_001',
            akDta: { 
                    ssoTkn: rspDta.ssoTkn,
                    frnYn: rspDta.frnYn,
                  },  
            rturUrl: `${self.$env.siteUrl}/login`,
            rturUrlCaloMethd: 'GET'                     //  리턴URL호출메소드
            });
          return;
          }else if('44' == rspC && '404' == rspDtc){
          //부정로그인 대상입니다.
          sso.callScreen({
            akUrl: '/exView/login/login_03_001',
            akDta: { 
                    ssoTkn: rspDta.ssoTkn,
                    onlCstTpC: rspDta.onlCstTpC,
                  },  
            rturUrl: `${self.$env.siteUrl}/login`,
            rturUrlCaloMethd: 'GET'                     //  리턴URL호출메소드
            });
          }else if('44' == rspC && '434' == rspDtc){
          //2단계 인증 대기
            sso.callScreen({
            akUrl: '/exView/login/lv2CtfLogin_01_001',
            rturUrl: `${self.$env.siteUrl}/login`,
            rturUrlCaloMethd: 'GET'                     //  리턴URL호출메소드
            });
          }else if(rspDtc == "000") {
              let objData = {};
              objData["lMembersSsoTkn"]  = rspDta.ssoTkn;
              objData["lMembersAcesTkn"] = rspDta.acesTkn;
              objData["lMembersRnwTkn"]  = rspDta.rnwTkn;

              window.sessionStorage.setItem('lMembersTknObj', JSON.stringify(objData))
              self.$store.commit("session/setLMembersTkn", objData);

              if (self.$device.isMobile && self.form.autoLogin) {
                self.setCookie(process.env.SSO_RNW_TKN_KEY, rspDta.rnwTkn, 360)
              }

              return resolve(rspDtc);
            }
            
            // 비밀번호 일치하지 않음.
            if(userIdDupYn == "Y") {

              // GFR 로그인              
              return resolve(rspDtc);

              /*
              // 오류처리(계속진행)
              if(rspC == "44" && rspDtc == "401") {
                self.loginProgress = false;
                return self.$modal
                  .error("아이디 또는 비밀번호를 다시 확인해 주세요.")
                  .then(() => {
                    self.refs.password.focus();
                    return reject();
                  });
              }

              // 제휴사 약관 미동의 회원입니다. (GFR 로그인)
              if(rspC == "44" && rspDtc == "419") {
                return resolve(rspDtc);
              }
              */

            }else{
              // 등록된 사용자가 아님. -> GFR 로그인              
              return resolve(rspDtc);
            }

            // 한번 더 SSO 로그인하여 후처리(오류처리)
            sso.callLogin({
              akUrl: '/exBiz/login/login_01_001',
              akDta: {
                onlId: self.form.username,
                cstPswd: self.form.password
              },
              aftPcMd: "1",  // 후처리모드
              rturUrl: window.location.href,
              rturUrlCaloMethd: 'GET',
              callback : function(rspDta) {
                let rspC   = rspDta.rspC;
                let rspDtc = rspDta.rspDtc;
              }
            });

            self.loginProgress = false;
            return reject();
          }
        });

        /*
        // 서버에서 응답이 없는경우 처리 (setTimeout)
        setTimeout(() => {
          self.loginProgress = false;
          return reject();
        }, 5 * 60 * 1000);
        */
      });
    },

    // changeLpointMember() {
    //   return new Promise((resolve, reject) => {
    //     this.$modal.show(ChangeLpointModal, {
    //       resolve,
    //       reject
    //     }, {
    //       width: this.$device.isMobile ? "100%" : "400px",
    //       height: "auto"
    //     });
    //   });
    // },

    async onClickGuestOrder()
    {
      this.$cookies.remove(this.$env.ORDER_STORAGE_KEY);
      await this.$axios.get("/api/order/no-member/clear");
      return this.$router.push('/order/no-member');
    },

    incrementHiddenNumber(){
      this.hiddenNumber ++;      
    },
  },
};
</script>
